import React from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import classes from "./Vacancies.module.scss";
import Button from "../../GlobalComponents/Button/Button";

import imgManagers from "../../../assets/img/VacanciesPage/img-managers.webp";
import imgRetentions from "../../../assets/img/VacanciesPage/img-retentions.webp";
import {useTranslation} from "react-i18next";

const Vacancies = () => {
    const { t } = useTranslation();
    return (
        <section className={`${classes.extra}`}>
            <div className={`container ${classes.extra__container}`}>
                <div className={`${classes.extra__content} ${classes.extra__contentMoreSpace}`}>
                    <h2 className={`${classes.extra__title}`}>{t("vacancies_title_2")}</h2>
                    <p className={`${classes.extra__descr}`}>{t("vacancies_descr_2")}</p>
                    <div className={`${classes.extra__controls}`}>
                        <Button>{t("btn_respond")}</Button>
                    </div>

                    <h2 className={`${classes.extra__title} ${classes.extra__titleSlim}`}>{t("vacancies_title_3")}</h2>
                    <p className={`${classes.extra__descr} ${classes.extra__descrMoreSpace}`}>
                        {t("vacancies_descr_3")}
                    </p>

                    <h2 className={`${classes.extra__title} ${classes.extra__titleSlim}`}>{t("vacancies_title_7")}</h2>
                    <ul className={`${classes.extra__list}`}>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_4")}</li>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_5")}</li>
                    </ul>
                </div>
                <div className={`${classes.extra__preview}`}>
                    <img className={`${classes.extra__previewImg} ${classes.extra__previewImgFirst}`} src={imgManagers} alt=""/>
                </div>
            </div>

            <section className={`${classes.features}`}>
                <div className={`container ${classes.extra__container} ${classes.extra__featuresContainer}`}>
                    <div className={`${classes.extra__features}`}>
                        <h2 className={`${classes.extra__featuresTitle}`}>{t("vacancies_title_4")}</h2>
                        <ul className={`${classes.extra__list}`}>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_6")}</li>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_7")}</li>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_8")}</li>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_9")}</li>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_10")}</li>
                            <li className={`${classes.extra__item} ${classes.extra__itemTip}`}>{t("vacancies_descr_11")}</li>
                        </ul>
                    </div>
                    <div className={`${classes.extra__features}`}>
                        <h2 className={`${classes.extra__featuresTitle}`}>{t("vacancies_title_5")}</h2>
                        <ul className={`${classes.extra__list}`}>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_12")}</li>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_13")}</li>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_14")}</li>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_15")}</li>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_16")}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className={`${classes.vacancy} ${classes.vacancy__second}`}>
                <div className={`container ${classes.extra__container}`}>
                    <div className={`${classes.extra__content} ${classes.extra__contentMoreSpace}`}>
                        <h2 className={`${classes.extra__title}`}>{t("vacancies_title_6")}</h2>
                        <p className={`${classes.extra__descr}`}>{t("vacancies_descr_17")}</p>
                        <div className={`${classes.extra__controls}`}>
                            <Button>{t("btn_respond")}</Button>
                        </div>

                        <h2 className={`${classes.extra__title} ${classes.extra__titleSlim}`}>{t("vacancies_title_7")}</h2>
                        <ul className={`${classes.extra__list}`}>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_18")}</li>
                            <li className={`${classes.extra__item}`}>{t("vacancies_descr_19")}</li>
                        </ul>
                    </div>
                    <div className={`${classes.extra__preview} ${classes.extra__previewAbsolute}`}>
                        <img className={`${classes.extra__previewImg} ${classes.extra__previewImgSecond}`} src={imgRetentions} alt=""/>
                    </div>
                </div>
            </section>

            <div className={`container ${classes.extra__container} ${classes.extra__featuresContainer}`}>
                <div className={`${classes.extra__features}`}>
                    <h2 className={`${classes.extra__featuresTitle}`}>{t("vacancies_title_8")}</h2>
                    <ul className={`${classes.extra__list}`}>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_20")}</li>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_21")}</li>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_22")}</li>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_23")}</li>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_24")}</li>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_25")}</li>
                    </ul>
                </div>
                <div className={`${classes.extra__features}`}>
                    <h2 className={`${classes.extra__featuresTitle}`}>{t("vacancies_title_9")}</h2>
                    <ul className={`${classes.extra__list}`}>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_26")}</li>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_27")}</li>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_28")}</li>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_29")}</li>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_30")}</li>
                        <li className={`${classes.extra__item}`}>{t("vacancies_descr_31")}</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Vacancies;
