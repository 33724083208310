import React, {useLayoutEffect, useRef} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import classes from "./Advantages.module.scss";

import imgAdvantages from "../../../assets/img/main/img-advantages-1.webp";
import {Back} from "gsap/gsap-core";
import Button from "../../GlobalComponents/Button/Button";
import {useTranslation} from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const Advantages = () => {
    const main = useRef();
    const { t } = useTranslation();

    return (
        <section className={`${classes.advantages}`} ref={main}>
            <div className={`container ${classes.advantages__container}`}>
                <div className={`${classes.advantages__content}`}>
                    <h2 className={`${classes.advantages__title}`}>{t("main_advantages_title_1")}</h2>
                    <div className={`${classes.advantages__descrWrap}`}>
                        <p className={`${classes.advantages__descr}`}>{t("main_advantages_descr_1")}</p>
                        <p className={`${classes.advantages__descr}`}>{t("main_advantages_descr_2")}</p>
                        <p className={`${classes.advantages__descr}`}>{t("main_advantages_descr_3")}</p>
                    </div>
                    <Button>{t("btn_learn_more")}</Button>
                </div>
                <div className={`${classes.advantages__preview}`}>
                    <img className={`${classes.advantages__previewImg}`} src={imgAdvantages} alt=""/>
                </div>
            </div>
        </section>
    )
}

export default Advantages;
