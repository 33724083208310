import VacanciesPage from '../pages/VacanciesPage/VacanciesPage';
import HomePage from '../pages/HomePage/HomePage';


export const routes = [
    { path: '/', element: HomePage },
    { path: '/trading-strategies', element: VacanciesPage },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading-strategies', element: VacanciesPage },
];

export const headerRoutes = [
    { id: 1, path: 'trading-strategies', text: "header_nav_1" },
    // { id: 2, path: 'asset-classes', text: 'Account Types' },
    // { id: 3, path: 'contact', text: 'FAQs' },
    // { id: 4, path: 'about', text: 'About Us' },
];
