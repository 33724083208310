import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from "./Features.module.scss";

import icon1 from "../../../assets/img/main/img-features-header.svg";
import {useTranslation} from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const Features = () => {
    const main = useRef();
    const { t } = useTranslation();

    return (
        <section className={`${classes.features}`} ref={main}>
            <div className="container">
                <div className={`${classes.features__terms}`}>
                    <div className={`${classes.features__termsItem}`}>
                        <h3 className={`${classes.features__termsTitle}`}>{t("main_features_title_1")}</h3>
                        <p className={`${classes.features__termsDescr}`}>{t("main_features_descr_1")}</p>
                    </div>

                    <div className={`${classes.features__termsItem}`}>
                        <h3 className={`${classes.features__termsTitle}`}>{t("main_features_title_2")}</h3>
                        <p className={`${classes.features__termsDescr}`}>{t("main_features_descr_2")}</p>
                    </div>
                </div>
                <div className={`${classes.features__header}`}>
                    <img className={`${classes.features__headerImg}`} src={ icon1 } alt=""/>
                    <h3 className={`${classes.features__headerTitle}`}>{t("main_features_title_3")}</h3>
                    <p className={`${classes.features__headerDescr}`}>{t("main_features_descr_3")}</p>
                </div>
                <div className={`custom-scrollbar ${classes.features__listWrap}`}>
                    <div className={`${classes.features__list}`}>
                        <section className={`${classes.features__item}`}>
                            <div className={`${classes.features__itemDecoDot}`}></div>
                            <h3 className={`${classes.features__itemTitle}`}>{t("main_features_title_4")}</h3>
                            <p className={`${classes.features__itemDescr}`}>{t("main_features_descr_4")}</p>
                        </section>

                        <section className={`${classes.features__item}`}>
                            <div className={`${classes.features__itemDecoDot}`}></div>
                            <h3 className={`${classes.features__itemTitle}`}>{t("main_features_title_5")}</h3>
                            <p className={`${classes.features__itemDescr}`}>{t("main_features_descr_5")}</p>
                        </section>

                        <section className={`${classes.features__item}`}>
                            <div className={`${classes.features__itemDecoDot}`}></div>
                            <h3 className={`${classes.features__itemTitle}`}>{t("main_features_title_6")}</h3>
                            <p className={`${classes.features__itemDescr}`}>{t("main_features_descr_6")}</p>
                        </section>

                        <section className={`${classes.features__item}`}>
                            <div className={`${classes.features__itemDecoDot}`}></div>
                            <h3 className={`${classes.features__itemTitle}`}>{t("main_features_title_7")}</h3>
                            <p className={`${classes.features__itemDescr}`}>{t("main_features_descr_7")}</p>
                        </section>

                        <section className={`${classes.features__item}`}>
                            <div className={`${classes.features__itemDecoDot}`}></div>
                            <h3 className={`${classes.features__itemTitle}`}>{t("main_features_title_8")}</h3>
                            <p className={`${classes.features__itemDescr}`}>{t("main_features_descr_8")}</p>
                        </section>

                        <section className={`${classes.features__item}`}>
                            <div className={`${classes.features__itemDecoDot}`}></div>
                            <h3 className={`${classes.features__itemTitle}`}>{t("main_features_title_9")}</h3>
                            <p className={`${classes.features__itemDescr}`}>{t("main_features_descr_9")}</p>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features
