import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import classes from "./Banner.module.scss";
import { useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';

import imgBanner from "../../../assets/img/main/img-banner-people.webp";

gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
    const main = useRef();
    const { t } = useTranslation();
    return (
        <section className={`${classes.banner}`} ref={main}>
            <div className={`container ${classes.banner__container}`}>
                <div className={`${classes.banner__content}`}>
                    <h1 className={`${classes.banner__title}`}>
                        {t("main_banner_title")}
                    </h1>
                    <p className={`${classes.banner__descr}`}>
                        {t("main_banner_descr")}
                    </p>
                </div>
                <div className={`${classes.banner__preview}`}>
                    <img className={`${classes.banner__previewImg}`} src={ imgBanner } alt=""/>
                </div>
            </div>
        </section>
    )
}

export default Banner;
