import React from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from "./Feedback.module.scss";
import Button from "../../GlobalComponents/Button/Button";

gsap.registerPlugin(ScrollTrigger);

const Feedback = () => {
    return (
        <section className={`${classes.feedback}`}>
            <div className={`container ${classes.feedback__container}`}>
                <div className={`${classes.feedback__decoCircleLeft}`}></div>
                <div className={`${classes.feedback__decoCircleRight}`}></div>
                <h2 className={`${classes.feedback__title}`}>Готовы ли Вы к финансовому успеху?</h2>
                <b className={`${classes.feedback__subtitle}`}>Мы будем рады видеть Вас на собеседовании!</b>
                <p className={`${classes.feedback__descr}`}>Пожалуйста, предоставьте информацию ниже, и наши менеджеры свяжутся с Вами в ближайшее время!</p>
                <form className={`${classes.feedback__form}`}>
                    <div className={`${classes.feedback__row}`}>
                        <input className={`${classes.feedback__input}`} type="text" name="name" placeholder="Имя"/>
                        <input className={`${classes.feedback__input}`} type="email" name="email" placeholder="Электронная почта"/>
                    </div>
                    <div className={`${classes.feedback__row}`}>
                        <input className={`${classes.feedback__input}`} type="number" name="phone" placeholder="Телефон"/>
                        <input className={`${classes.feedback__input}`} type="text" name="city" placeholder="Город"/>
                    </div>
                    <div className={`${classes.feedback__checkbox}`}>
                        <label>
                            <input type="checkbox" name="agreement"/>
                            Соглашаюсь с Условиями и положениями данного веб-сайта
                        </label>
                    </div>
                    <div className={`${classes.feedback__controls}`}>
                        <Button>Отправить!</Button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Feedback
