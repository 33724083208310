import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from "./Accounts.module.scss";
import Button from "../../GlobalComponents/Button/Button";

import icon1 from "../../../assets/img/main/img-accounts-1.webp";
import icon2 from "../../../assets/img/main/img-accounts-2.webp";
import icon3 from "../../../assets/img/main/img-accounts-3.webp";
import icon4 from "../../../assets/img/main/img-accounts-4.webp";
import {useTranslation} from "react-i18next";
// import bg from "../../../assets/img/main/bg-accounts.png";

gsap.registerPlugin(ScrollTrigger);

const Accounts = () => {
    const main = useRef();
    const { t } = useTranslation();
    const data = [
        {
            img: icon1,
            title: t("main_accounts_title_2")
        },
        {
            img: icon2,
            title: t("main_accounts_title_3")
        },
        {
            img: icon3,
            title: t("main_accounts_title_4")
        },
        {
            img: icon4,
            title: t("main_accounts_title_5")
        }
    ];

    return (
        <section className={`${classes.accounts}`} ref={main}>
            <div className="container">
                <h2 className={`${classes.accounts__title}`}>{t("main_accounts_title_1")}</h2>
                <div className={`${classes.accounts__list}`}>
                    {data.map((item, i) => {
                        return (
                            <section className={`${classes.accounts__item}`} key={i + 1}>
                                <div className={`${classes.accounts__itemPreview}`}>
                                    <img className={`${classes.accounts__itemPreviewImg}`} src={ item.img } alt=""/>
                                </div>
                                <h3 className={`${classes.accounts__itemDescr}`}>{ item.title }</h3>
                            </section>
                        )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Accounts
