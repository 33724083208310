import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import classes from "./Terms.module.scss";
import Button from "../../GlobalComponents/Button/Button";

import avatar1 from "../../../assets/img/main/img-avatar-1.webp";
import avatar2 from "../../../assets/img/main/img-avatar-2.webp";
import avatar3 from "../../../assets/img/main/img-avatar-3.webp";
import {useTranslation} from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const Terms = () => {
    const main = useRef();
    const { t } = useTranslation();
    const data = [
        {
            title: t("main_terms_title_2"),
            descr: t("main_terms_descr_1")
        },
        {
            title: t("main_terms_title_3"),
            descr: t("main_terms_descr_2")
        },
        {
            title: t("main_terms_title_4"),
            descr: t("main_terms_descr_3")
        },
        {
            title: t("main_terms_title_5"),
            descr: t("main_terms_descr_4")
        },
        {
            title: t("main_terms_title_6"),
            descr: t("main_terms_descr_5")
        }
    ];

    return (
        <section className={`${classes.terms}`} ref={main}>
            <div className={`container ${classes.terms__container}`}>
                <div className={`${classes.terms__content}`}>
                    <h2 className={`${classes.terms__title}`}>{t("main_terms_title_1")}</h2>
                    <ul className={`${classes.terms__list}`}>
                        {data.map((item, i) => {
                            return (
                                <li className={`${classes.terms__item}`} key={i + 1}>
                                    <h3 className={`${classes.terms__itemTitle}`}>{ item.title }</h3>
                                    <p className={`${classes.terms__itemDescr}`}>{ item.descr }</p>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
                <div className={`${classes.terms__avatars}`}>
                    <div className={`${classes.terms__avatarsItem}`}>
                        <img className={`${classes.terms__avatarsImg}`} src={ avatar1 } alt=""/>
                        <div className={`${classes.terms__avatarsCheckbox}`}></div>
                        <div className={`${classes.terms__avatarsIncome}`}>+250$</div>
                    </div>

                    <div className={`${classes.terms__avatarsItem}`}>
                        <img className={`${classes.terms__avatarsImg}`} src={ avatar2 } alt=""/>
                        <div className={`${classes.terms__avatarsCheckbox}`}></div>
                        <div className={`${classes.terms__avatarsIncome}`}>+2 700$</div>
                    </div>

                    <div className={`${classes.terms__avatarsItem}`}>
                        <img className={`${classes.terms__avatarsImg}`} src={ avatar3 } alt=""/>
                        <div className={`${classes.terms__avatarsCheckbox}`}></div>
                        <div className={`${classes.terms__avatarsIncome}`}>+1 200$</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Terms
