import React from "react";
import classes from "./Button.module.scss";
import Cookies from 'js-cookie';

const Button = ({ children, white }) => {
  const lang = Cookies.get('i18next');
  return (
        <a href="" className={`${classes.button} ${white ? classes.buttonWhite : ""}`}>
            <span>{children}</span>
        </a>
  );
};

export default Button;
